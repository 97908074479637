<template>
  <div>
    <van-nav-bar fixed @click-left="leftReturn" title="人脸认证" left-arrow>
    </van-nav-bar>
<!--    <rxNavBar title="人脸认证" androidOrIOSFlag="true"></rxNavBar>-->
    <iframe style="height: 700px; width: 10rem; display: block"
            :src="url_" frameborder=0 name="showHere" scrolling=auto
    ></iframe>
  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {Dialog, NavBar, Overlay, Picker, Popup, PullRefresh, Toast,} from "vant";
import dialMobile from "../../../components/rongxun/rx-dialMobile/dialMobile";

export default {
  name: "checkFangxinAuthentication",
  components:{
    [NavBar.name]:NavBar,
    [Toast.name]:Toast,
    [Dialog.name]:Dialog,
    [Picker.name]: Picker,
    [Popup.name]:Popup ,
    rxNavBar,
    dialMobile,
    [PullRefresh.name]:PullRefresh,
    [Overlay .name]: Overlay ,
  },
  data(){
    return{
      url_:this.$route.query.path,
    }
  },
  methods:{
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
  }

}
</script>

<style scoped>

</style>